<template>
  <div class="customerList">
    <div class="content">
      <div class="pop-content">
        <!-- 客户信息 -->
        <el-tabs v-model="tabChecked" class="demo-tabs" @tab-change="tabCheck">
          <el-tab-pane
              :label="'正常（' + countInfo.normal + '）'"
              name="normalList"
          >
          </el-tab-pane>
          <el-tab-pane :label="'作废（' + countInfo.discard + '）'" name="voidList">
          </el-tab-pane>
        </el-tabs>
        <!-- 列表 -->
        <div class="normalList">
          <div class="queryBoxOne">
            <div class="btnBox">
              <el-button type="primary" @click='add' v-if='tabChecked !="voidList"'>
                <el-icon>
                  <Plus/>
                </el-icon>
                <span class="text">新增</span>
              </el-button>
            </div>
            <div class="queryItem">
              <el-input v-elInput class="w240" placeholder="客户名称" @keydown.enter="getList" v-model="formData.query"
                        clearable>
              </el-input>
              <div class="queryBtn">
                <el-button type="primary" @click="getList"
                >
                  <el-icon>
                    <Search/>
                  </el-icon
                  >
                </el-button>
              </div>
            </div>
          </div>
          <div class="tabList">
            <!-- 列表 -->
            <el-table
                :data="tableData"
                style="width: 100%"
                height="100%"
                class="zh-no-table-border"
                :row-class-name="tableRowClassName"
                :cell-style="{border:'none'}"
                :header-row-style="{border:'none'}"
                @row-click="openInfoDia"
            >
              <el-table-column label="序号" width="100" align="center">
                <template #default="scope">
                  <div class="table_text">{{ ((pageInfo.pageNumber - 1) * pageInfo.pageSize) + scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column width="300" prop="punterName" label="客户名称" align="center" show-overflow-tooltip/>
              <el-table-column
                  prop="contactUserName"
                  label="联系人"
                  width="200"
                  align="center"
                  show-overflow-tooltip
                  row-class-name="table_text"
              >
                <template #default="scope">{{ scope.row.contactUserName }}</template>
              </el-table-column>
              <el-table-column
                  prop="contactUserPhone"
                  label="联系电话"
                  width="200"
                  align="center"
                  row-class-name="table_text"
                  show-overflow-tooltip
              >
                <template #default="scope">{{ scope.row.contactUserPhone }}</template>
              </el-table-column>
              <el-table-column
                  label="地址"
                  width="200"
                  align="center"
                  row-class-name="table_text"
                  show-overflow-tooltip
              >
                <template #default="scope">{{ scope.row.address }}</template>
              </el-table-column>
              <el-table-column
                  label="变更人"
                  width="200"
                  align="center"
                  row-class-name="table_text"
                  show-overflow-tooltip
              >
                <template #default="scope">{{ scope.row.updateUserName }}</template>
              </el-table-column>
              <el-table-column
                  label="变更时间"
                  align="center"
              >
                <template #default="scope">
                  <div class="table_text">{{ $filters.timeFormat(scope.row.updateTime, 1) }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="mainPage">
          <el-pagination
              v-model:current-page="pageInfo.pageNumber"
              layout="total, prev, pager, next , jumper"
              :total="pageInfo.total"
              v-model:page-size='pageInfo.pageSize'
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog v-model="showDia" align-center :title="addInfo.punterId?'编辑客户信息':'新增客户信息'" width="920px" :close-on-click-modal="false" class="customerDia">
    <div class="content">
      <div class="query">
          <div class="item">
             <div class="name">
            <span class="red">*</span>客户名称
            </div>
            <div class="input" >
              <el-input  v-elInput class="queryInput"  maxlength="100"  v-model="addInfo.punterName" placeholder="请输入客户名称"  v-if='!addInfo.punterId'></el-input>
                <div class="text" v-else>{{addInfo.punterName}}</div>
            </div>
          </div>
          <div class="item">
             <div class="name">联系人
            </div>
            <div class="input">
              <el-input  v-elInput class="queryInput"   maxlength="100" v-model="addInfo.contactUserName" placeholder="请输入联系人名称"></el-input>
            </div>
          </div>
          <div class="item">
             <div class="name">联系人电话
            </div>
            <div class="input">
              <el-input  v-elInput class="queryInput"
                maxlength="13" v-model="addInfo.contactUserPhone" onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" placeholder="请输入联系人电话"></el-input>
            </div>
          </div>
          <div class="item">
             <div class="name">公司地址
            </div>
            <div class="input">
              <el-input  v-elInput class="queryInput"   maxlength="100" v-model="addInfo.address" placeholder="市/区/街道"></el-input>
            </div>
          </div>
      </div>
    </div>
     <template #footer>
          <el-button @click="clsoeShowDia">取消</el-button>
          <el-button class="main" type="primary" @click='handleOkAdd' :disabled="!isOk">{{addInfo.punterId?'确认编辑':'确认新增'}}</el-button>
         </template>
    </el-dialog>
     <el-dialog
      v-model="infoDia"
      title="客户信息"
      width="920px"
      class="supplierDia"
      align-center
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="query">
          <div class="item">
            <div class="name"><span class="red">*</span>客户名称</div>
            <div class="text">
              {{goodsInfo.punterName || '暂无'}}
            </div>
          </div>
          <div class="item">
            <div class="name">联系人</div>
            <div class="text">
              {{goodsInfo.contactUserName || '暂无'}}
            </div>
          </div>
          <div class="item">
            <div class="name">联系人电话</div>
            <div class="text">
            {{goodsInfo.contactUserPhone || '暂无'}}
            </div>
          </div>
          <div class="item">
            <div class="name">公司地址</div>
            <div class="text">
             {{goodsInfo.address || '暂无'}}
            </div>
          </div>
        </div>
      </div>
      <template #footer v-if='tabChecked == "normalList"'>
        <el-button @click="discard(goodsInfo)" class="zh-btn btn1">作废</el-button>
        <el-button class="main btn1" @click="add(goodsInfo)" type="primary" >编辑</el-button>
      </template>
      <template #footer v-else>
        <el-button @click="resume(goodsInfo)" class="zh-btn btn1">恢复</el-button>
      </template>
    </el-dialog>
    <Modal :show="confirmDIa" :title='confirmInfo.title' :content='confirmInfo.content' @ok='handleOk' @close="handleClose"></Modal>
  </div>
</template>
<script>
import {computed, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {ArrowDown, Plus, Search} from "@element-plus/icons-vue";
import Modal from '@/components/modal/index.vue'
import {ElMessageBox} from "element-plus";
import {tableRowClassName} from '@/utils/tool.js'
import {
  punterAdd,
  punterCensus,
  punterDiscard,
  punterEdit,
  punterList,
  punterLIstDiscard,
  punterResume
} from '@/utils/api/punter.js'
import {usePagination} from "@utils/tool/page";

export default {
  name: "customerList",
  components: {
    Plus,
    ArrowDown,
    Search,
    Modal
  },
   setup() {
    const { proxy } = getCurrentInstance()
    const tabChecked = ref("normalList");
    const tabCheck = function (tab) {
      getList()
      getCount()
    };
     const showDia = ref(false); // 控制弹窗是否展示
     const confirmDIa = ref(false) // 控制确认弹窗是否打开
     const infoDia = ref(false);//详情弹窗
    const goodsInfo = reactive({
      name: "",
      contactUserName: "",
      contactUserPhone: "",
      address: "",
      punterId: "",
    });
    //打开详情弹窗
    const openInfoDia = (row) => {
      infoDia.value = true;
      goodsInfo.punterName = row.punterName;
      goodsInfo.contactUserName = row.contactUserName;
      goodsInfo.contactUserPhone = row.contactUserPhone;
      goodsInfo.address = row.address;
      goodsInfo.punterId = row.punterId;
    };
    //关闭详情弹窗
    const clsoeInfoDia = () => {
      infoDia.value = false;
    };
     const confirmInfo = reactive({
      title:"",
      content:'',
      punterId:"",
      type:0
     })
     const {pageInfo} = usePagination()
    const handleOk = async()=>{
      let api = confirmInfo.type == 1?punterDiscard:punterResume
      let res = await api({
        punterId:confirmInfo.punterId
      })
       if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
      proxy.$message.success('操作成功')
      tabCheck()
      confirmDIa.value = false
       infoDia.value = false;
    }
    const handleClose = (e)=>{
      confirmDIa.value = e
    }
    const formData = reactive({
      query:""
    })
    const clsoeShowDia = () => {
      ElMessageBox.confirm("客户信息未保存，是否确认取消", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
        showDia.value = false;
      });
    };
    //新增修改信息
    const addInfo = reactive({
      contactUserName:"",//联系人姓名
      address:"",//地址
      contactUserPhone:"", //联系人电话
      punterId:"",//客户id
      name:"",//客户名称
    })
    //作废
    const discard = async (e)=>{
      confirmInfo.title = '作废'
      confirmInfo.content = '确认该客户作废'
      confirmInfo.punterId = e.punterId
      confirmInfo.type = 1 // 作废
      confirmDIa.value = true
    }
    //恢复
    const resume = async (e)=>{
      confirmInfo.title = '恢复'
      confirmInfo.content = '确认该客户恢复使用'
      confirmInfo.punterId = e.punterId
      confirmInfo.type = 2 // 恢复
      confirmDIa.value = true
    }
    //编辑
    const add = async(e)=>{
      clearAddInfo()
      if(e){
        //赋值 客户信息
        addInfo.contactUserName = e.contactUserName
        addInfo.address = e.address
        addInfo.contactUserPhone = e.contactUserPhone
        addInfo.punterId = e.punterId
        addInfo.punterName = e.punterName
      }
      showDia.value = true
    }
    //确认新增编辑
    const handleOkAdd = async()=>{
      let api = addInfo.punterId?punterEdit:punterAdd
      if(!addInfo.punterName){
        proxy.$message.warning('请填写客户名称')
        return
      }
       if (addInfo.punterName.length < 2) {
        proxy.$message.warning("客户名称最少2个字");
        return;
      }
      let res = await api(addInfo)
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
       proxy.$message.success("操作成功");
      tabCheck()
      showDia.value = false
      infoDia.value = false;
    }
    const clearAddInfo = () => {
      const keys = Object.keys(addInfo);
      let obj = {};
      keys.forEach((item) => {
        obj[item] = "";
      });
      Object.assign(addInfo, obj);
    };
    const tableData = ref([]);
    const data = reactive({
      countInfo: {
        normal: 0,
        discard: 0,
      },
    });
    //获取数量统计
    const getCount =async ()=>{
      let res =await punterCensus()
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
      data.countInfo.normal = res.data.normal
      data.countInfo.discard = res.data.discard
    }
    getCount()
    //获取列表
    const  getList = async ()=>{
      let api = tabChecked.value === 'normalList'? punterList: punterLIstDiscard
      let obj = Object.assign(pageInfo,formData)
      let res =await api(obj)
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
      tableData.value = res.data?.list || []
      pageInfo.total = res.data?.total || 0
    }
    getList()
    const handleCurrentChange = (val) => {
       pageInfo.pageNumber = val
      getList()
    };
    const isOk = computed(()=>{
      return addInfo.punterName
    })
    return {
      isOk,
      clsoeShowDia,
      tabChecked,
      tabCheck,
      ...toRefs(data),
      tableData,
      pageInfo,
      showDia,
      formData,
      handleCurrentChange,
      getList,
      discard,
      resume,
      addInfo,
      add,
      handleOkAdd,
      confirmDIa,
      confirmInfo,
      handleClose,
      handleOk,
      tableRowClassName,
       openInfoDia,
      infoDia,
      clsoeInfoDia,
      goodsInfo
    };
  },
};
</script>

<script setup></script>

<style lang="scss" scoped>
.customerList {
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
   >.content{
        border-radius: 16px;
        background: #fff;
        padding: 12px 32px;
        height: 100%;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;

     .pop-content {
       position: relative;
       height: 100%;
     }
    }
 .normalList {
    position: relative;
    height: calc(100vh - 268px);
  }
  .tabList {
    margin-top: 34px;
    height: calc(100% - 40px);
    .btn {
      display: inline-block;
      color: $fsColor3;
    }
  }
}

.customerDia{
  .content{
    padding: 0 16px;
  }
}
 .query{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .item{
    width: 50%;
    margin-bottom: 20px;
    font-weight: 400;
    .red{
      color: #E54E4E;
      position: relative;
      top: 2px;
    }

    .name {
      color: #737480;
    }
    .queryInput{
      width: 360px;
    }
    .input{
      position: relative;
       margin-top: 10px;
      .addBtn{
        height: 36px;
        line-height: 36px;
        position: absolute;
        left: 316px;
        top: 0;
        color: $fsColor3;
        margin-right: 10px;
        font-weight: normal;
        cursor: pointer;
      }
      .avatar-uploader{
        width: 168px;
        height: 126px;
        background: #F2F4F7;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar-uploader-icon{
          font-size: 40px;
          line-height: 126px;
          color: #DCDEE0;
        }
      }
    }
      .text{
      line-height: 1;
      font-size: 14px;
      color: $fsColor;
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
:deep(.el-table__row) {
  cursor: pointer;
}
</style>
